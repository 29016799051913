import './RuviForm.css';

import React, { useState, useEffect } from 'react';
import ServiceApi from './ServiceApi';
import Status from './Status';
import Dialogs from './Dialogs';
import Utils from './Utils';
import Menu from './Menu.js';

const RuviForm = ({ history, me, setMe, i18n, selectedId, setSelectedId, havaintolomake, kasvikoodi, logout, elyt }) => {

    const [unSaved, setUnsaved] = useState(false);
    const [loadedId, setLoadedId] = useState(0);
    const [currentRound, setCurrentRound] = useState(0);
    const [infoTitle, setInfoTitle] = useState('');
    const [infoContent, setInfoContent] = useState('');
    const [infoVisible, setInfoVisible] = useState(false);
    const [modifyReason, setModifyReason] = useState('');
    const [askModifyReason, setAskModifyReason] = useState(false);
    const [askPlantCodeVerify, setAskPlantCodeVerify] = useState(false);
    const [havaintokasvulohko, setHavaintokasvulohko] = useState(
        {
            "uusi": true,
            "kasvulohkoid": selectedId,
            "todettukasvikoodi": null,
            "havaintoreittiid": null,
            "havaintokierrokset": []
        }
        );

    if (selectedId.id !== loadedId) {
        setLoadedId(selectedId.id);
    }

    useEffect(() => {
        ServiceApi.getHavaintoKasvulohko(setHavaintokasvulohko, loadedId);
    }, [loadedId]
    );

    useEffect(() => {
        if (!unSaved) {
            if (selectedId.round) {
                setCurrentRound(selectedId.round)
            }
            else if (havaintokasvulohko && havaintokasvulohko.havaintokierrokset && havaintokasvulohko.havaintokierrokset.length>0) {
                const kierrokset = havaintokasvulohko.havaintokierrokset.sort((a, b) => a.havaintokierros - b.havaintoKierros);
                let index = 0;
                for (; index < kierrokset.length; index++) {
                    const kierros = kierrokset[index];
                    if (kierros.status === 0) { //ongoing round found
                        setCurrentRound(kierros.havaintokierros)
                        break;
                    }
                }
                if (kierrokset && index === kierrokset.length) {
                    setCurrentRound((index + 1 > havaintolomake.length) ? havaintolomake.length : index + 1)
                    // not found  => last or new round
                }
                else {
                    setCurrentRound(index + 1)
                }
            }
            else {
                setCurrentRound(1);
            }
        }
    }, [havaintokasvulohko, havaintolomake, unSaved, selectedId]
    );

    const cancel = () => {
        ServiceApi.getHavaintoKasvulohko(setHavaintokasvulohko, loadedId);
        setUnsaved(false);
        setSelectedId({...selectedId, id: loadedId, saved:true})
    }

    const alldone = (lomake, havainto) => {
        console.log(lomake, havainto);
        for (let index = 0; index < lomake.length; index++) {
            const element = lomake[index];
            if (element !== 99  &&  !havainto.includes(element)) {
                return false;
            }
        } 
        return true;
    }

    const save = (_status) => {
        let status = _status ? _status : 0 
        console.log('status', status);
        //console.log('modfy reason', modifyReason);
        let havaintokasvulohkokierros = havaintokasvulohko.havaintokierrokset.filter((kierros) => (kierros.havaintokierros === currentRound));
        let havaintolomakekierros = havaintolomake.filter((kierros) => (kierros.havaintokierros === currentRound));
        let havaittuKasvi = havaintokasvulohko.todettukasvikoodi ? havaintokasvulohko.todettukasvikoodi : havaintokasvulohko.ilmoitettukasvi;
        if (havaintokasvulohkokierros && havaintokasvulohkokierros[0] && havaintokasvulohkokierros[0].status === 1 && modifyReason.length===0) {
            setAskModifyReason(true);
            return;
        }

        if (havaintokasvulohko && !havaintokasvulohko.todettukasvikoodi && currentRound === havaintolomake.length) {
            setAskPlantCodeVerify(true);
            return;
        }

        //console.log(havaintolomakekierros[0].havainto.filter((havainto) => (havainto.id !== 99)).length, havaintokasvulohkokierros[0] && havaintokasvulohkokierros[0].havainto.filter((havainto) => (havainto.havaintotyyppi !== 99)).length);
        if (havaintokasvulohkokierros && havaintokasvulohkokierros[0] && havaintokasvulohkokierros[0].status === 1 && modifyReason.length > 0) {
                let prev = kasvulohkoHavainto(currentRound, 99).lisatieto;
                prev = prev ? prev + '\n\n' :'';
                setKasvulohkoHavaintoLisatieto(havaintokasvulohkokierros[0].havaintokierros, 99, prev + modifyReason);
        }
        if (status === 2) {
            let prev = kasvulohkoHavainto(currentRound, 99).lisatieto;
            prev = prev ? prev + '\n\n' :'';
//            setKasvulohkoHavaintoLisatieto(currentRound, 99, prev + 'lohko ohitettu');
            for (let index = 0; index < havaintolomake.length; index++) {
                setKasvulohkoHavaintoLisatieto(index+1, 99, prev + 'lohko ohitettu');
                setKasvulohkoStatus(index+1,status);
            }
        }


        if ( status === 0 && havaintolomakekierros[0] && havaintokasvulohkokierros[0] &&
             havaintolomakekierros[0].havainto && havaintokasvulohkokierros[0].havainto &&
             alldone(havaintolomakekierros[0].havainto
             .filter(havainto => havainto.vainkasvikoodeille ? 
                kasvikoodiFound(havainto.vainkasvikoodeille,havaittuKasvi)
                :!kasvikoodiFound(havainto.eikasvikoodeille,havaittuKasvi))
                .map((havainto) => (havainto.id)),
                havaintokasvulohkokierros[0].havainto.filter( havainto => havainto.havaintoarvo || havainto.lisatieto )
                .map((havainto) => (havainto.havaintotyyppi)))) {
            status = 1;
        }
        setKasvulohkoStatus(currentRound,status);
        ServiceApi.saveHavaintoKasvulohko(havaintokasvulohko)
        .then((resp) =>{
            setUnsaved(false);
            setSelectedId({...selectedId, saved:true, round: currentRound})
            setHavaintokasvulohko(resp);
            setModifyReason('');
        })
        .catch((error) => {
            console.error('Tallennus epäonnistui', havaintokasvulohko);
            setInfoTitle('Tallennus epäonnistui');
            setInfoContent('Yritä uudelleen myöhemmin');
            setInfoVisible(true);
        })
    }

    const shortenString = (nimi) => {
        if (nimi.length > 20 ) {
            return nimi.substring(0, 18)+ '...';
        }
        else {
            return nimi;
        }
    }
    
    const [dropdownActive, setDropdownActive] = useState(null);

    let kasvikoodi_dd = kasvikoodi ?
        <div>
        <div className={`dropdown ${dropdownActive}`}>
            <div className="dropdown-trigger">
                <button className="button" aria-haspopup="true" aria-controls="dropdown-menu">
                    <span>{kasvikoodi.filter((kasvikoodi) => 
                        (kasvikoodi.koodi === (havaintokasvulohko.todettukasvikoodi ? havaintokasvulohko.todettukasvikoodi: havaintokasvulohko.ilmoitettukasvi)))
                        .map((kasvikoodi)=> (''+kasvikoodi.koodi +'-' +shortenString(kasvikoodi.nimi)))}</span>
                    <span className="icon is-small" onClick={() => dropdownActive ? setDropdownActive(null) : setDropdownActive('is-active') }>
                        <i className={`fas ${(dropdownActive ? 'fa-angle-up': 'fa-angle-down')}`} aria-hidden="true"></i>
                    </span>
                </button>
            </div>
            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content" style={{maxHeight: '20em', overflow: 'auto'}}>
                    {kasvikoodi && kasvikoodi.map((kasvikoodi) => (
                    <div className="dropdown-item" id={kasvikoodi.koodi} key={kasvikoodi.koodi}
                     onClick = {() => {
                            setHavaintokasvulohko({...havaintokasvulohko, todettukasvikoodi: kasvikoodi.koodi});
                            setDropdownActive(null);
                            if(!unSaved) {setUnsaved(true); setSelectedId({...selectedId, saved:false})}
                        }}>
                        {''+kasvikoodi.koodi +'-' +kasvikoodi.nimi}
                    </div>
                    ))}
                </div>
            </div>
        </div>
        <div className="field">
            <input className="is-checkradio is-medium" type="checkbox" checked={havaintokasvulohko.todettukasvikoodi ? true: false}
                id='tarkistettu' name='tarkistettu'
                onChange={() => {
                    if (!havaintokasvulohko.todettukasvikoodi) {
                        setHavaintokasvulohko({ ...havaintokasvulohko, todettukasvikoodi: havaintokasvulohko.ilmoitettukasvi });
                    }
                    else {
                        setHavaintokasvulohko({ ...havaintokasvulohko, todettukasvikoodi: null });
                    }
                    if(!unSaved) {setUnsaved(true); setSelectedId({...selectedId, saved:false})}
                }} />
            <label htmlFor="tarkistettu">Tarkistettu</label>
        </div>
        </div>            
        :null    

    const havaintokierrospvm = (kierros) => {
        let havaintokierros = havaintokasvulohko.havaintokierrokset.filter((havaintokierrokset) => (
            havaintokierrokset.havaintokierros === kierros));
        if (havaintokierros.length > 0 && havaintokierros[0].pvm) {
            return Utils.getFormattedDate(havaintokierros[0].pvm);
        }
        else {
            return '--.--.--'
        }
    }

    const getStatusColor = (kierros) => {
        let havaintokierros  = havaintokasvulohko.havaintokierrokset ? havaintokasvulohko.havaintokierrokset.filter((havaintokierros) => (havaintokierros.havaintokierros === kierros)):null;
        let status = havaintokierros && havaintokierros[0] ? havaintokierros[0].status: null;
        if (status === null) {
            return 'has-text-grey';
        } else if (status === Status.NotObserved) {
            return 'has-text-warning';
        } else if (status === Status.Observed) {
            return 'has-text-success';
        } else if (status === Status.Skipped) {
            return 'has-text-danger';
        }
    }

    let rounds = havaintolomake ? havaintolomake.map((kierros) => (
        <button key={kierros.havaintokierros} 
          onClick={() => {setCurrentRound(kierros.havaintokierros)}}
          className={`button is-rounded is-size-7-mobile is-size-7-tablet ${kierros.havaintokierros === currentRound ? '' : 'is-light'}`}>
            <span className={`icon ${getStatusColor(kierros.havaintokierros)}`}>
                <div style ={{display: "grid"}}>
                    <div style ={{gridColumn: 1, gridRow: 1}}>
                        <i className={`fas fa-lg fa-circle `}></i>
                    </div>
                    <div style ={{gridColumn: 1, gridRow: 1}}>
                        <span className = "has-text-white ">{kierros.havaintokierros}</span>
                    </div>
                  </div>
            </span>
             <span >{`${havaintokierrospvm(kierros.havaintokierros)}`}</span>
        </button>
    ))
        : null


    const kasvulohkoHavainto = (currentRound,havaintoId) => {
        let kierros  = havaintokasvulohko.havaintokierrokset.filter((kierros) => (kierros.havaintokierros === currentRound))
        if (kierros[0] && kierros[0].havainto) {
            let havainto = kierros[0].havainto.filter((havainto) => (havainto.havaintotyyppi===havaintoId));
            if (havainto[0]) {
                return havainto[0];
            }
        }
        return {havaintoarvo: null, lisatieto: null};
    } 

    const setKasvulohkoStatus = (round, status) => {
        let kierros = havaintokasvulohko.havaintokierrokset.filter((kierros) => (kierros.havaintokierros === round))
        if (kierros[0] && kierros[0].havainto) {
            kierros[0].status = status
            setHavaintokasvulohko({ ...havaintokasvulohko });
        }
        if (!kierros[0]) {
            havaintokasvulohko.havaintokierrokset.push({
                "havaintokierros": round,
                "status": status,
                "havainto": []
            })
            setHavaintokasvulohko({ ...havaintokasvulohko });
        }
        //console.log(havaintokasvulohko);
        return havaintokasvulohko
    } 

    const setKasvulohkoHavaintoArvo = (round,havaintoId, havaintoarvo) => {
        let found=false;
        let kierros  = havaintokasvulohko.havaintokierrokset.filter((kierros) => (kierros.havaintokierros === round))
        if (kierros[0] && kierros[0].havainto) {
            let havainto = kierros[0].havainto.filter((havainto) => (havainto.havaintotyyppi===havaintoId));
            if (havainto[0]) {
                if (havainto[0].havaintoarvo===havaintoarvo) {
                    havainto[0].havaintoarvo=null;
                }
                else {
                    havainto[0].havaintoarvo=havaintoarvo;
                }
                setHavaintokasvulohko({...havaintokasvulohko});
                found = true;
            }
        }
        if (!found) {
            if (!kierros[0]) {
                havaintokasvulohko.havaintokierrokset.push({
                    "havaintokierros": round,
                    "status": 0,
                    "havainto": [
                        {
                        "havaintotyyppi": havaintoId,
                        "havaintoarvo": havaintoarvo,
                        }
                    ]
                })
            }
            else {
                //console.log(kierros);
                if (!kierros[0].havainto) {
                    kierros[0].havainto=[]; 
                }                
                kierros[0].havainto.push({
                    "havaintotyyppi": havaintoId,
                    "havaintoarvo": havaintoarvo,
                })
            }
            setHavaintokasvulohko({...havaintokasvulohko});
        }
        //console.log(havaintokasvulohko);
        if(!unSaved) {setUnsaved(true); setSelectedId({...selectedId, saved:false})}

    } 

    const setKasvulohkoHavaintoLisatieto = (round,havaintoId, lisatieto) => {
        console.log(round,havaintoId, lisatieto);
        let found=false;
        let kierros  = havaintokasvulohko.havaintokierrokset.filter((kierros) => (kierros.havaintokierros === round))
        if (kierros[0] && kierros[0].havainto) {
            let havainto = kierros[0].havainto.filter((havainto) => (havainto.havaintotyyppi===havaintoId));
            if (havainto[0]) {
                havainto[0].lisatieto= (lisatieto === '' ? null : lisatieto);
                setHavaintokasvulohko({...havaintokasvulohko});
                found = true;
            }
        }
        if (!found) {
            if (!kierros[0]) {
                havaintokasvulohko.havaintokierrokset.push({
                    "havaintokierros": round,
                    "status": 0,
                    "havainto": [
                        {
                        "havaintotyyppi": havaintoId,
                        "lisatieto": lisatieto,
                        }
                    ]
                })
            }
            else {
                console.log(kierros);
                if (!kierros[0].havainto) {
                    kierros[0].havainto=[]; 
                }
                kierros[0].havainto.push({
                    "havaintotyyppi": havaintoId,
                    "lisatieto": lisatieto,
                })
            }
            setHavaintokasvulohko({...havaintokasvulohko});
        }
        console.log(havaintokasvulohko);
        if(!unSaved) {setUnsaved(true); setSelectedId({...selectedId, saved:false})}

    } 

    const kasvikoodiFound = (kasvikoodilista, ilmoitettukasvi, todettukasvikoodi) => {
        let kasvikoodi = todettukasvikoodi ? todettukasvikoodi: ilmoitettukasvi;
        return kasvikoodilista ? kasvikoodilista.split(', ').includes(''+kasvikoodi): false ;
    }

    let havainnot = havaintolomake && currentRound !== 0 
    ? havaintolomake.filter((kierros) => (kierros.havaintokierros === currentRound))[0].havainto
    .filter(havainto => havainto.vainkasvikoodeille ? 
        kasvikoodiFound(havainto.vainkasvikoodeille,havaintokasvulohko.ilmoitettukasvi, havaintokasvulohko.todettukasvikoodi)
        :!kasvikoodiFound(havainto.eikasvikoodeille,havaintokasvulohko.ilmoitettukasvi, havaintokasvulohko.todettukasvikoodi))
        .map((havainto) => {
            if (havainto.id !== 99 || kasvulohkoHavainto(currentRound,havainto.id).lisatieto) {
            return (
            <div key={havainto.id}>
            <div className="field">
                <hr className="dropdown-divider"></hr>
                <div className="control">
                    <h4 className="title is-4">{`${havainto.nimi}`}</h4>
                    <div className="buttons are-medium pagination">
                        {havainto.havaintoarvo && havainto.havaintoarvo.map((havaintoarvo) => (
                            <div key={havaintoarvo.id} style={{width: '100%'}} >
                            <button key={havaintoarvo.id} style={{width: '90%'}} className={`button 
                                ${havaintoarvo.id === kasvulohkoHavainto(currentRound,havainto.id).havaintoarvo ? '' : 'is-light'}`}
                                onClick={() => {setKasvulohkoHavaintoArvo(currentRound, havainto.id, havaintoarvo.id)}}
                                >
                                {havaintoarvo.short}
                            </button>
                            <span className='has-tooltip-multiline has-tooltip-left' data-tooltip={havaintoarvo.nimi}>
                                 <i className="fas fa-info" /> 
                            </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="field">
                <div className="control">
                    {99 === havainto.id &&
                    <span>{Utils.getFormattedDate(kasvulohkoHavainto(currentRound,havainto.id).paivityspvm ? 
                        kasvulohkoHavainto(currentRound,havainto.id).paivityspvm
                        :kasvulohkoHavainto(currentRound,havainto.id).luontipvm)}</span>
                    }
                    <textarea className="textarea" placeholder="lisätietoja" rows="2" 
                        value={kasvulohkoHavainto(currentRound,havainto.id).lisatieto ?
                            kasvulohkoHavainto(currentRound,havainto.id).lisatieto : ''}
                        onChange ={(event) => {99 !== havainto.id && setKasvulohkoHavaintoLisatieto(currentRound, havainto.id, event.target.value)}}    >
                    </textarea>
                </div>
            </div>
            </div>
            )}
            else {
                return (<div key='99'/>)
            }
        })
    : null

    const back = (from_start) => {
        setSelectedId({id:0, saved: true});
        history.push(from_start ? '/start': '/form')
    }

    let sideBarHidden = !unSaved && (selectedId.id===0 || selectedId.map);

    return (
        <>
        <div id='ruvi-list'>
            <Menu history={history} me={me} setMe={setMe} logout={logout} elyt={elyt}/>
            {/* <button className="button" onClick={() => back(true)}><i className="fas fa-lg fa-tasks"></i></button> */}
         </div>
         {!sideBarHidden &&  
        <div id='ruvi-form' >
        <div className="field">
           {Dialogs.ConfirmationWithReason('Anna syy valmiin havainnoinnin muutokselle'
                ,modifyReason, setModifyReason, 'Jatka tallennusta', askModifyReason, setAskModifyReason, save)}
            {Dialogs.Information(infoTitle, infoContent, infoVisible, setInfoVisible)}
            {Dialogs.Information('Kasvikoodin tarkistus', 'Kasvikoodi täytyy merkitä tarkistetuksi viimeisellä havaintokierroksella', askPlantCodeVerify, setAskPlantCodeVerify)}
           <div>
            <div className="field has-text-left">
                {!unSaved &&
                    <><span className="icon is-medium" onClick={() => back(selectedId.start)}>
                     <i className="fas fa-2x fa-arrow-left" ></i>
                    </span></>
                }
                {unSaved &&    
                    <><span className="icon is-medium" onClick={() => {}}>
                    <i className="fas fa-2x fa-edit" ></i>
                   </span></>
                
                }
                <span style={{float:'right'}}>{`Käynnissä havaintokierros: ${currentRound}`}</span>
            </div>
            <div className="has-text-left">
                {rounds}
            </div>
            <div className="field has-text-left">
                <hr className="dropdown-divider"></hr>
                <span>{havaintokasvulohko.rekisteritunnus} - {havaintokasvulohko.nimi} {havaintokasvulohko.kasvulohko}</span>
            </div>
            <hr className="dropdown-divider"></hr>
            <div className="field has-text-left">
                <h4 className="title is-4">Ilmoitettu kasvikoodi</h4>
                <span>{kasvikoodi.filter((kasvikoodi) => (kasvikoodi.koodi === havaintokasvulohko.ilmoitettukasvi))
                    .map((kasvikoodi)=> (''+kasvikoodi.koodi +'-' +kasvikoodi.nimi))}</span>
            </div>
            <div className="field has-text-left">
                <hr className="dropdown-divider"></hr>
                <h4 className="title is-4">Havaittu kasvikoodi</h4>
                {kasvikoodi_dd}
            </div>
            {havainnot}
            <div className="field has-text-left">
                <hr className="dropdown-divider"></hr>
                <div className="columns">
                    <div className="column">
                        <button className="button is-fullwidth is-success" disabled={ !unSaved ? 'disabled' :null} onClick={() => save(null)}>Tallenna</button>
                    </div>
                    <div className="column">
                        <button className="button is-fullwidth is-link" disabled={ !unSaved ? 'disabled' :null} onClick={() => cancel()}>Peruuta muutokset</button>
                    </div>
                </div>
            </div>
            <div className="field has-text-left">
                <button className="button is-text has-text-danger" onClick={() => save(2)}>Ohita lohko</button>
            </div>
        </div>
        </div>
        </div>
        }
        </>
    )
}

export default RuviForm;