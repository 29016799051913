export const authScreenLabels = {
    fi: {
      'Sign in to your account': 'Kirjaudu järjestelmään',
      Email: 'Sähköposti',
      'Phone number': 'Puhelinnumero',
      Username: 'Käyttäjätunnus',
      Password: 'Salasana',
      'Forget your password? ': 'Unohtuiko salasana? ',
      'Reset password': 'Salasanan palautus',
      'Sign In': 'Kirjaudu',
      'Sign Out': 'Kirjaudu ulos',
      'Sign Up': 'Rekisteröidy',
      'Sign Up Account': 'Rekisteröidy käyttäjäksi',
      'No account? ': 'Ei tunnusta? ',
      'Create account': 'Rekisteröidy tästä',
      'Reset your password': 'Salasanan palautus',
      'Send Code': 'Tilaa palautuskoodi',
      'Enter your username': 'Syötä käyttäjätunnuksesi',
      'Enter your password': 'Syötä salasanasi',
      'Enter your phone number': 'Syötä puhelinnumerosi',
      'Back to Sign In': 'Takaisin kirjautumiseen',
      'Have an account? ': 'On jo tili? ',
      'Sign in': 'Kirjaudu',
      'Create Account': 'Rekisteröidy',
      Code: 'Palautuskoodi',
      'New Password': 'Uusi salasana',
      'Resend Code': 'Lähetä uusi koodi',
      'Username cannot be empty': 'Käyttäjätunnus ei voi olla tyhjä',
      'Password cannot be empty': 'Salasana ei voi olla tyhjä',
      'Invalid verification code provided, please try again.':
        'Väärä palautuskoodi, yritä uudestaan.',
      'Username/client id combination not found.': 'Käyttäjää ei löytynyt.',
      'User does not exist': 'Käyttäjää ei löytynyt.',
      'Incorrect username or password': 'Väärä käyttäjätunnus tai salasana.',
      'Invalid password format': 'Salasana ei kelpaa',
      'Attempt limit exceeded, please try after some time.':
        'Liian monta yritystä, yritä myöhemmin uudestaan.',
      Hello: 'Kirjautuneena nimellä',
    },
  };
  