import axios from 'axios';

class ServiceApi {
    static getGeojson(url) {
        return new Promise(function (resolve, reject) {
            axios.get(url, { crossdomain: true })
                .then((response) => {
                    console.log(url.split('/')[3].split('?')[0], response.data);
                    resolve(response.data);
                })
                .catch((error) => {
                    console.log('' + url.split('/')[3].split('?')[0] + ' failed', error);
                    reject(error);
                })
        })
    }

    static getMe(setMe) {
        axios.get(window.apiUrl + '/me', { crossdomain: true })
            .then((resp) => {
                console.log('/me', resp.data);
                setMe(resp.data);
            })
            .catch((error) => {
                console.error(error);
            })
    }

    static getHavaintolomake(setHavaintolomake) {
        axios.get(window.apiUrl + '/havaintolomake', { crossdomain: true })
            .then((resp) => {
                console.log('/havaintolomake', resp.data);
                setHavaintolomake(resp.data);
            })
            .catch((error) => {
                console.error(error);
            })
    }

    static getHavaintoKasvulohko(setHavaintokasvulohko, id) {
        axios.get(window.apiUrl + '/havaintokasvulohko/' + id, { crossdomain: true })
            .then((resp) => {
                console.log('/havaintokasvulohko/' + id, resp.data);
                if (resp.data.kasvulohkoid) {
                    if (!resp.data.havaintokierrokset) {
                        resp.data.havaintokierrokset=[];
                    }
                    setHavaintokasvulohko(resp.data);
                }
                else {
                    setHavaintokasvulohko({
                        "uusi": true,
                        "kasvulohkoid": id,
                        "todettukasvikoodi": null,
                        "havaintoreittiid": null,
                        "havaintokierrokset": []
                    })
                }

            })
            .catch((error) => {
                console.error(error);
            })
    }

    static saveHavaintoKasvulohko(havaintokasvulohko) {
        return new Promise(function (resolve, reject) {
        if ( havaintokasvulohko.uusi ){
            axios.post(window.apiUrl + '/havaintokasvulohko', havaintokasvulohko, { crossdomain: true })
            .then((resp) => {
                console.log('/havaintokasvulohko', resp.data);
                resolve(resp.data);
            })
            .catch((error) => {
                reject(error);
            })
        }
        else  {
            axios.put(window.apiUrl + '/havaintokasvulohko/' + havaintokasvulohko.kasvulohkoid, havaintokasvulohko, { crossdomain: true })
            .then((resp) => {
                console.log('/havaintokasvulohko', resp.data);
                resolve(resp.data);
            })
            .catch((error) => {
                reject(error);
            })
        }
    })
    }

    static deleteHavaintoKasvulohko(id, round) {
        return new Promise(function (resolve, reject) {
            axios.delete(window.apiUrl + '/havaintokasvulohko/' + id + '/' + round, { crossdomain: true })
                .then((resp) => {
                    console.log('/havaintokasvulohko/' + id, resp.data);
                    resolve(resp.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                })
        })
    }

    static getHavaintoKasvulohkot(setHavaintokasvulohko, ely) {
        return new Promise(function (resolve, reject) {
            axios.get(window.apiUrl + '/havaintokasvulohko', { params: {ely: ely}, crossdomain: true })
                .then((resp) => {
                    console.log('/havaintokasvulohko', resp.data);
                    if (resp.data) {
                        setHavaintokasvulohko(resp.data);
                        resolve(resp.data);
                    }
                    else {
                        // to prevent loop if not found
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                })
            })
    }

    static getHavaintoKasvulohkotiedot(setHavaintokasvulohkotiedot, ely) {
        return new Promise(function (resolve, reject) {
            axios.get(window.apiUrl + '/havaintokasvulohkotiedot', { params: {ely: ely}, responseType: 'blob', crossdomain: true })
                .then((resp) => {
                    console.log('/havaintokasvulohkotiedot', resp);
                    if (resp.data) {
                        setHavaintokasvulohkotiedot(resp.data);
                        resolve(resp.data);
                    }
                    else {
                        // to prevent loop if not found
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                })
            })
    }

    static getKasvikoodi(setKasvikoodi) {
        axios.get(window.apiUrl + '/kasvikoodi', { crossdomain: true })
            .then((resp) => {
                console.log('/kasvikoodi'/*, resp.data*/);
                if (resp.data) {
                    setKasvikoodi(resp.data);
                }
                else {
                    // to prevent loop if not found
                }
            })
            .catch((error) => {
                console.error(error);
            })
    }

    static getElyt(setElyt) {
        axios.get(window.apiUrl + '/ely', { crossdomain: true })
            .then((resp) => {
                console.log('/ely'/*, resp.data*/);
                if (resp.data) {
                    setElyt(resp.data);
                }
                else {
                    // to prevent loop if not found
                }
            })
            .catch((error) => {
                console.error(error);
            })
    }

    static getTavoite(setTavoite, ely) {
        return new Promise(function (resolve, reject) {
            axios.get(window.apiUrl + '/tavoite', { params: {ely: ely},  crossdomain: true })
                .then((resp) => {
                    console.log('/tavoite' , resp.data);
                    if (resp.data) {
                        setTavoite(resp.data);
                        resolve(resp.data);
                    }
                    else {
                        // to prevent loop if not found
                    }
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                })
            })
        }
}

export default ServiceApi;
