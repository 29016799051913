import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { HashRouter as Router } from 'react-router-dom';
import i18next from 'i18next';
import translations from './translations';
import { Auth, I18n } from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import { authScreenLabels } from './amplifyConfig';
import axios from 'axios';
import './App.css';
import RuviMap from './RuviMap';
import RuviForm from './RuviForm';
import RuviStart from './RuviStart';
import RuviSummary from './RuviSummary';
import ServiceApi from './ServiceApi';

const i18n = i18next.createInstance();
i18n.init({
  lng: 'fi',
  resources: translations,
});
I18n.setLanguage('fi');
I18n.putVocabularies(authScreenLabels);

if (window.serviceEnv && window.serviceEnv.startsWith('_REPLACE')) {
  window.serviceEnv = process.env.REACT_APP_SERVICE_ENV;
}
if (window.authUserPoolId && window.authUserPoolId.startsWith('_REPLACE')) {
  window.authUserPoolId = process.env.REACT_APP_USER_POOL_ID;
}
if (window.authClientId && window.authClientId.startsWith('_REPLACE')) {
  window.authClientId = process.env.REACT_APP_CLIENT_ID;
}
if (window.authRegion && window.authRegion.startsWith('_REPLACE')) {
  window.authRegion = process.env.REACT_APP_REGION;
}
if (window.apiUrl && window.apiUrl.startsWith('_REPLACE')) {
  window.apiUrl = process.env.REACT_APP_API_URL;
}

console.log("auth parameter: ",window.authUserPoolId,window.authClientId,window.authRegion)
// init AWS auth
Auth.configure({
  userPoolId: window.authUserPoolId,
  userPoolWebClientId: window.authClientId,
  region: window.authRegion
});

const logout = () => {
  console.log('logout');
  Auth.signOut();
}

// Set token to every headers of every request
axios.interceptors.request.use(config => {
  return Auth.currentSession()
    .then(session => {
      config.headers.Authorization = `Bearer ${session.getAccessToken().getJwtToken()}`;
      return Promise.resolve(config);
    })
    .catch(() => {
      return Promise.resolve(config);
    });
});


function App() {

  const [selectedId, setSelectedId] = useState({id: 0, saved: true});
  const [havaintolomake, setHavaintolomake] = useState(null);
  const [kasvikoodi, setKasvikoodi] = useState(null);
  const [elyt, SetElyt] = useState(null);
  const [me, setMe] = useState(null);
  const [round, setRound] = useState(1);
  const size = useWindowSize();

  //load basic data
  if (!havaintolomake && !me &&!kasvikoodi && !elyt) {
    ServiceApi.getMe(setMe);
    ServiceApi.getHavaintolomake(setHavaintolomake);
    ServiceApi.getKasvikoodi(setKasvikoodi);
    ServiceApi.getElyt(SetElyt);
  }
  
  // const AppMap = ({ history }) => {
  //   return (
  //     <RuviMap history={history} i18n={i18n} selectedId={selectedId} setSelectedId={setSelectedId} />
  //   )
  // };

  const App= ({ history }) => {
    return (
      <>
        <RuviMap history={history} i18n={i18n} selectedId={selectedId} setSelectedId={setSelectedId} 
            ely={me && me.elytunnus ? me.elytunnus : 99} round={round} setRound={setRound} />
        <RuviForm history={history} me={me} setMe={setMe} i18n={i18n} selectedId={selectedId} havaintolomake={havaintolomake}
            setSelectedId={setSelectedId} kasvikoodi={kasvikoodi} logout={logout} elyt={elyt}/>
        { history.location.pathname === '/start' &&
          <RuviStart history={history} me={me} setMe={setMe} setSelectedId={setSelectedId} havaintolomake={havaintolomake}
            round={round} setRound={setRound} logout={logout} size={size} kasvikoodi={kasvikoodi} elyt={elyt}></RuviStart>
        }
        { history.location.pathname === '/summary' &&
          <RuviSummary history={history} me={me} setMe={setMe} havaintolomake={havaintolomake} round={round} setRound={setRound}
            logout={logout} kasvikoodi={kasvikoodi} elyt={elyt}></RuviSummary>
        }
      </>
    )
  };

  // const AppStart = ({history}) =>{
  //   return <RuviStart history={history} me={me} setSelectedId={setSelectedId} havaintolomake={havaintolomake} ></RuviStart>
  // }

  return (
    <div className="App">
      <Router>
        <Switch>
          {/* <Route path='/login' render={AppLogin} /> */}
          {/* <Route path='/map' render={AppMap} /> */}
          <Route path='/form' render={App} />
          <Route path='/start' render={App} />
          <Route path='/summary' render={App} />
          <Route render={() => <Redirect to='/start' />} />
        </Switch>
      </Router>
    </div>
  );
}

//Hook

const isClient = typeof window === 'object';

function getSize() {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  };
}

function useWindowSize() {

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!(typeof window === 'object')) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
} 

export default withAuthenticator(App,{
  includeGreetings: false
}
);
