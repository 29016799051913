import './RuviStart.css';

import React, { useState, useEffect } from 'react';
import ServiceApi from './ServiceApi';
import Status from './Status';
import Dialogs from './Dialogs';
import Utils from './Utils';
import Menu from './Menu.js';

const RuviStart = ({ history, me, setMe, setSelectedId, havaintolomake, logout, round, setRound, size, kasvikoodi, elyt}) => {

    const [havaintokasvulohkot, setHavaintokasvulohkot] = useState(null);
    const [infoikkuna, setInfoikkuna] = useState(false);
    const [muokkausikkuna, setMuokkausikkuna] = useState(false);
    const [poistoikkuna, setPoistoikkuna] = useState(false);
    const [valittukasvulohkotiedot, setValittukasvulohkotiedot] = useState(null);
    const [havaintokertatiedot, setHavaintokertatiedot] = useState(
        {
            "kasvulohkoid": null,
            "kierros": null,
        }
    );
    const[extraVisible,setExtraVisible] = useState(false);
    const [loading, setLoading] = useState(false);


    const getHavaintoKasvulohkot = async (me) => {
        if (me && me.elytunnus) {
            var loadingtimer = setTimeout(() => setLoading(true), 1000);
            await ServiceApi.getHavaintoKasvulohkot(setHavaintokasvulohkot, me.elytunnus);
            setLoading(false);
            clearTimeout(loadingtimer);

        }
    }

    useEffect(() => {
        getHavaintoKasvulohkot(me);
    }, [me]
    );

    const aloitaTarkastus = (kasvulohkoid, status, kierros) => {
        setSelectedId({id:kasvulohkoid, saved:true, start:true, round:kierros});
        setRound(kierros)
        history.push('/form');
    }

    const naytaInfo = (kasvulohkoid) => {
        let filtered = havaintokasvulohkot && havaintokasvulohkot.filter((lohko) => (lohko.id === kasvulohkoid));
        if (filtered && filtered[0]) {
            setValittukasvulohkotiedot(filtered[0]);
        }
        setInfoikkuna(true);
    }

    const naytaKartalla = (kasvulohkoid) => {
        setSelectedId({id:kasvulohkoid, saved:true, map:true});
        setRound(round)
        history.push('/form');
    }

    const getStartObsButton = (kasvulohkoid, lohkohavaintokierrokset, havaintolomakepituus) => {
        let roundsNotObservedLength = 0;
        let roundsObservedLength = 0;

        if (lohkohavaintokierrokset) {
            lohkohavaintokierrokset.forEach(element => {
                if (element.status === Status.NotObserved) {
                    roundsNotObservedLength++;
                } else if (element.status === Status.Observed) {
                    roundsObservedLength++;
                }
                else if (element.status === Status.Skipped) {
                    roundsObservedLength++;
                }
            });
        }

        let kierros = 1
        let buttonText = 'Aloita havainnointi ' + kierros;
        if (havaintolomakepituus === roundsObservedLength) {
            buttonText = 'Ei havainnoitavaa';
        } else if (lohkohavaintokierrokset) {
            if (lohkohavaintokierrokset.length === roundsObservedLength) {
                kierros = roundsObservedLength + 1
                buttonText = 'Aloita havainnointi ' + kierros;
            } else {
                kierros = lohkohavaintokierrokset.length - roundsNotObservedLength + 1;
                buttonText = 'Jatka havainnointia ' + kierros;
            }
        }

        return <td key={`obs-${kasvulohkoid}`}>
                <button disabled={havaintolomakepituus === roundsObservedLength ? 'disabled' : ''}
                    className="button"
                    onClick={() => aloitaTarkastus(kasvulohkoid, 0, kierros)}>
                    {buttonText}
                </button>
            </td>;
    }

    const getEmptyRoundElement = (kasvulohkoid, havaintokierros, extra) => {
        return <td key={`status-${kasvulohkoid}-${havaintokierros}`}>
                <button disabled="disabled" className="button is-small is-white">
                    <i className={`fas fa-md fa-circle ${extra ? 'has-text-link' :''}`}></i>
                    <span className={`is-size-6 ${size.width >500 ? "is-hidden-mobile is-hidden-tablet-only" :""}`} style={{marginLeft: '0.5rem'}}>--.--.--</span>
                </button>
            </td>;
    }

    const editObs = () => {
        aloitaTarkastus(havaintokertatiedot.kasvulohkoid, havaintokertatiedot.kierros.status, havaintokertatiedot.kierros.havaintokierros);
    }

    const deleteObs = async () => {
        await ServiceApi.deleteHavaintoKasvulohko(havaintokertatiedot.kasvulohkoid, havaintokertatiedot.kierros.havaintokierros)
        getHavaintoKasvulohkot(me);
        setSelectedId({id:0, saved:true}); //to clear form
    }

    const getRoundElementWithConfirmation = (kasvulohkoid, kierros, extra) => {
        if ( kierros.status === 0 || kierros.status) {
            return <td key={`status-${kasvulohkoid}-${kierros.havaintokierros}`} >
            <button className="button is-small is-white" onClick={() => {setHavaintokertatiedot({"kasvulohkoid": kasvulohkoid, "kierros": kierros}); setMuokkausikkuna(true)}}>
                <i className={`fas fa-md fa-circle ${getStatusColor(kierros.status, extra)}`}></i>
                <span className={`is-size-6 ${size.width >500 ? "is-hidden-mobile is-hidden-tablet-only" :""}`} style={{marginLeft: '0.5rem'}}>{Utils.getFormattedDate(kierros.pvm)}</span>
            </button>
        </td>;
        } else {
            return getEmptyRoundElement(kasvulohkoid, kierros.havaintokierros, extra);
        }
    }

    const getShowInfoButton = (kasvulohkoid) => {
        return <td key={`info-${kasvulohkoid}`}>
                <button className="button"
                    onClick={() => naytaInfo(kasvulohkoid)}> <i className="fas fa-md fa-exclamation"></i>
                </button>
            </td>;
    }

    const getShowMapButton = (kasvulohkoid) => {
        return <td key={`map-${kasvulohkoid}`}>
                <button className="button" onClick={() => naytaKartalla(kasvulohkoid)}>
                    <i className="fas fa-md fa-map-marker-alt"></i>
                </button>
            </td>;
    }

    const getStatusColor = (status, extra) => {
        if (status === null || status === Status.NotObserved) {
            return  'has-text-warning';
        } else if (status === Status.Observed) {
            return 'has-text-success';
        } else if (status === Status.Skipped) {
            return 'has-text-danger';
        }
    }

    const byKasvulohko = (a,b) => {
        console.log('byKasvulohko',mySort.func);
        //if (!a || !b) return 0;
        return ('' + a.nimi).localeCompare(b.nimi)* (mySort.dec ? -1 : 1)
    } 

    const byKasvikoodi = (a,b) => {
        console.log('byKasvikoodi',mySort.func);
        //if (!a || !b) return 0;
        return ((a.todettukasvikoodi ? a.todettukasvikoodi : a.ilmoitettukasvi)
            - (b.todettukasvikoodi ? b.todettukasvikoodi : b.ilmoitettukasvi))* (mySort.dec ? -1 : 1)
    } 

    const byStatus = (a,b) => {
        console.log('byStatus', mySort.func);
        let aStatus = 3
        if (a && a.havaintokierrokset) {
            let kierros = a.havaintokierrokset.filter((r) => r.havaintokierros === mySort.round);
            if (kierros && kierros[0]) {
                aStatus = kierros[0].status
            }
        }
        let bStatus = 3
        if (b && b.havaintokierrokset) {
            let kierros = b.havaintokierrokset.filter((r) => r.havaintokierros === mySort.round);
            if (kierros && kierros[0]) {
                bStatus = kierros[0].status
            }
        }
        return (aStatus-bStatus)* (mySort.dec ? -1 : 1)
    } 

    const byDate = (a,b) => {
        let aDate = new Date(0);
        if (a && a.havaintokierrokset) {
            let kierros = a.havaintokierrokset.filter((r) => r.havaintokierros === mySort.round);
            if (kierros && kierros[0]) {
                aDate = new Date(kierros[0].pvm)
            }
        }
        let bDate = new Date(0);
        if (b && b.havaintokierrokset) {
            let kierros = b.havaintokierrokset.filter((r) => r.havaintokierros === mySort.round);
            if (kierros && kierros[0]) {
                bDate = new Date(kierros[0].pvm)
            }
        }
        return (aDate-bDate)* (mySort.dec ? -1 : 1);
    } 

    const selectSortfunc = () => {
        if (mySort.func === 'byKasvulohko') return byKasvulohko
        else if (mySort.func === 'byKasvikoodi') return byKasvikoodi
        else if (mySort.func === 'byStatus') return byStatus
        else if (mySort.func === 'byDate') return byDate
        else return undefined;
    }

    const[mySort,setMySort] = useState({func: undefined, dec:false, round:1 });

    let obsParcels = havaintolomake && havaintokasvulohkot ? 
        havaintokasvulohkot.filter(lohko => (extraVisible && lohko.extra===1) || (lohko.havaintokierrokset || lohko.extra===null || lohko.extra ===2))
        .sort(selectSortfunc()).map((lohko) => (
        <tr key={`row-${lohko.id}`}>
            <td key={`name-${lohko.id}`}>{lohko.nimi}</td>
            <td key={`plantcode-${lohko.id}`}>{lohko.todettukasvikoodi ? lohko.todettukasvikoodi : lohko.ilmoitettukasvi}</td>
            {havaintolomake.map((kierros) => {
                let filtered = lohko.havaintokierrokset ? lohko.havaintokierrokset.filter((lohkokierros) => (kierros.havaintokierros === lohkokierros.havaintokierros)) : null;
                return (
                    filtered && filtered.length > 0 ? filtered.map((kierros) => (
                        getRoundElementWithConfirmation(lohko.id, kierros, lohko.extra)
                    )) : getEmptyRoundElement(lohko.id, kierros.havaintokierros, lohko.extra)
                )}
            )}
            {getShowInfoButton(lohko.id)}
            {getShowMapButton(lohko.id)}
            {getStartObsButton(lohko.id, lohko.havaintokierrokset, havaintolomake.length)}
        </tr>
    )) : null

    return (
        <div id='ruvi-start' className='field'>
            <div className={`pageloader is-left-to-right  ${loading ? 'is-active':''}`}><span className="title">Lataa....</span></div>
            {Dialogs.Information("Kasvulohkon tiedot", valittukasvulohkotiedot && <>
                <div>Maatilatunnus: {valittukasvulohkotiedot.rekisteritunnus}</div>
                <div>Kasvulohkotunnus: {valittukasvulohkotiedot.nimi} {valittukasvulohkotiedot.kasvulohko} </div>
                <div>Kasvuzone: {valittukasvulohkotiedot.kasvuzone}</div>
                <div>Pinta-ala: {valittukasvulohkotiedot.pintaala}</div>
                <div>Ilmoitettu kasvi: {valittukasvulohkotiedot.ilmoitettukasvi}{kasvikoodi && kasvikoodi.filter((kasvikoodi) => (kasvikoodi.koodi === valittukasvulohkotiedot.ilmoitettukasvi))
                    .map((kasvikoodi) => (' - ' + kasvikoodi.nimi))}
                </div>
                <div>Todettu kasvi: {valittukasvulohkotiedot.todettukasvikoodi}{kasvikoodi && kasvikoodi.filter((kasvikoodi) => (kasvikoodi.koodi === valittukasvulohkotiedot.todettukasvikoodi))
                    .map((kasvikoodi) => (' - ' + kasvikoodi.nimi))}
                </div>
                <div>Vuosi: {valittukasvulohkotiedot.vuosi}</div>
                    </>, infoikkuna, setInfoikkuna)}
            {Dialogs.ConfirmationWithSecondaryAction("Kasvulohkon havaintokerta", <>
                <div>Muokataanko vai poistetaanko kasvulohkon havaintokerta?</div>
                </>, muokkausikkuna, setMuokkausikkuna, {action: editObs, style: "is-success", caption: "Muokkaa"}, {action: () => {setPoistoikkuna(true)}, style: "is-danger", caption: "Poista"})}
            {Dialogs.Confirmation("Kasvulohkon havaintokerta", <>
                <div>Oletko varma, että kasvulohkon havaintokerta poistetaan?</div>
                </>, poistoikkuna, setPoistoikkuna, deleteObs)}
                <div className="rows">
                    <div className="row">
                        <div className="columns">
                            <div className="column">
                                <div>{`Olet kirjautunut käyttäjänä ${me && me.nimi}, ${me && me.ely}`}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="columns is-vcentered">
                            <div className="column is-narrow">
                                <Menu history={history} me={me} setMe={setMe} logout={logout} elyt={elyt}/>
                            </div>
                            <div className="column">
                                <h1 className="title">Kasvulohkot</h1>
                            </div>
                            <div className="column has-text-right-tablet">
                                <input className="is-checkradio is-medium" type="checkbox" checked={extraVisible}
                                    id='extraVisible' name='extraVisible'
                                    onChange={() => {setExtraVisible(!extraVisible)}} />
                                <label htmlFor="extraVisible">Extrat</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero">
                    <table className="table">
                        <thead>
                            <tr key="header-row">
                            <th key="header-parcel" 
                            onClick = {() => {
                                let dec = mySort.func === 'byKasvulohko'? !mySort.dec : false;
                                setMySort({func: 'byKasvulohko', dec:dec })
                            }}>{`Kasvulohko${mySort.func === 'byKasvulohko' ? (mySort.dec ? '↑' :'↓' ): '↕'}`}</th>
                            <th key="header-plantcode" 
                            onClick = {() => {
                                let dec = mySort.func === 'byKasvikoodi'? !mySort.dec : false;
                                setMySort({func: 'byKasvikoodi', dec:dec })
                            }}>{`Kasvikoodi${mySort.func === 'byKasvikoodi' ? (mySort.dec ? '↑' :'↓' ): '↕'}`}</th>
                            {havaintolomake && havaintolomake.map((kierros) => (
                                <th key={`header-${kierros.havaintokierros}`}
                                onClick = {() => {
                                    let dec = mySort.func === 'byStatus'? !mySort.dec : false;
                                    setMySort({func: 'byStatus', dec:dec, round: kierros.havaintokierros })
                                }}>#{`${kierros.havaintokierros}${mySort.func === 'byStatus' && mySort.round === kierros.havaintokierros ? (mySort.dec ? '↓' :'↑' ): '↕'}`}</th>
                            ))}
                            <th key="header-info"></th>
                            <th key="header-map"></th>
                            <th key="header-obs"></th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr key="footer-row">
                            <th key="header-parcel">Kasvulohko</th>
                            <th key="header-plantcode">Kasvikoodi</th>
                            {havaintolomake && havaintolomake.map((kierros) => (
                                <th key={`footer-${kierros.havaintokierros}`}>#{`${kierros.havaintokierros}`}</th>
                            ))}
                            <th key="footer-info"></th>
                            <th key="footer-map"></th>
                            <th key="footer-obs"></th>
                            </tr>
                        </tfoot>
                        <tbody>
                        {obsParcels}
                        </tbody>
                    </table>
                </div>
            </div>
    )
}

export default RuviStart;