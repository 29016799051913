class Utils {
    static getFormattedDate = (datestr) => {
        let date = datestr ? new Date(Date.parse(datestr)) : new Date();
        return `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}`;
    }
    static getFormattedDateTime = (datestr) => {
        let date = datestr ? new Date(Date.parse(datestr)) : new Date();
        return `${date.getDate()}.${date.getMonth()+1}.${date.getFullYear()}_${('0'+date.getHours()).slice(-2)}${('0'+date.getMinutes()).slice(-2)}${('0'+date.getSeconds()).slice(-2)}`;
    }
}

export default Utils;
