import 'ol/ol.css';
import './RuviMap.css';

import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { get } from 'ol/proj';
import { getCenter } from 'ol/extent';
import React, { useRef, useEffect, useState } from 'react';
import { Map, View } from 'ol';
import { Zoom } from 'ol/control';
import { defaults } from 'ol/interaction';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import * as olExtent from 'ol/extent';
import Point from 'ol/geom/Point';
import Cluster from 'ol/source/Cluster';
import { GeoJSON } from 'ol/format';
import { bbox } from 'ol/loadingstrategy';
import Select from 'ol/interaction/Select';
import { Fill, Stroke, Circle, Style, Text } from 'ol/style';
import { click } from 'ol/events/condition';
import ServiceApi from './ServiceApi';
import Dialogs from './Dialogs';

import GeoLocationControl from './GeoLocationControl';
import RoundControl from './RoundControl';

function RuviMap({ i18n, selectedId, setSelectedId, ely, round, setRound }) {

    const selectedIdRef = useRef(selectedId);
    //selectedIdRef.current = selectedId;
    
    const elyRef = useRef(ely);
    //elyRef.current = ely;

    const roundRef = useRef(round);
    //roundRef.current = round;

    const [infoVisible, setInfoVisible] = useState(false);

    const statusColorSelected = 'rgba(143,198,232,1.0)';

    const statusColorNotObserved = 'rgba(52,56,65,0.5)';
    const statusColorOngoing = 'rgba(247,207,61,0.7)';
    const statusColorObserved = 'rgba(72,199,116,0.7)';
    const statusColorSkipped = 'rgba(241,70,104,0.7)';
    const statusColorExtra = 'rgba(208,0,111,0.7)';

    const colorCluster = 'rgba(52,56,65,0.5)';
    const colorClusterStroke = 'rgb(52,56,65)';
    const textColor = 'rgb(0,0,0)';

    // Register projection
    proj4.defs('EPSG:3067', '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
    register(proj4);

    const projExtent = [50199.4814, 6582464.0358, 761274.6247, 7799839.8902];

    const proj = get('EPSG:3067');
    if (proj) {
        proj.setExtent(projExtent);
    }

    // Init layers
    const osmLayer = new TileLayer({
        source: new OSM({
            crossOrigin: 'anonymous',
            wrapX: false
        })
    });

    const getParcelStatusColor = function (status, extra, stroke) {
        if (status === null) {
            return extra === 1 ? statusColorExtra: statusColorNotObserved;
        } else if (status === 0) {
            return statusColorOngoing;
        } else if (status === 1) {
            return statusColorObserved;
        } else if (status === 2) {
            return statusColorSkipped;
        }
    }

    const parcelFill = function (feature) {
        return new Fill({
            color: getParcelStatusColor(feature.get('status'),feature.get('extra'))
        })
    };

    const parcelStroke = function (feature, resolution) {
        //console.log('parcelStroke', feature)
        return new Stroke({
            color: feature.get('id') === selectedIdRef.current.id ? statusColorSelected : getParcelStatusColor(feature.get('status'),feature.get('extra')),
            width: feature.get('id') === selectedIdRef.current.id ? resolution < 1 ? 10 : 6 : resolution < 1 ? 5 : 3
        })
    }

    const parcelText = function (feature, resolution) {
        //console.log(resolution)
        return new Text({
            text: resolution < 3 ? '' + feature.get('rekisteritunnus') + '\n' + feature.get('nimi') + ' ' + feature.get('kasvulohko') + '\n'
                + feature.get('pintaala') + '\n' + (feature.get('todettukasvi') ? feature.get('todettukasvi') : feature.get('ilmoitettukasvi'))
                : '' + feature.get('rekisteritunnus'),
            fill: new Fill({
                color: textColor
            }),
            overflow: true,
            font: resolution < 1 ? '14px sans-serif' : '10px sans-serif'
        });
    };

    const parcelStyles = function (feature, resolution) {
        return new Style({
            image: new Circle({
                fill: parcelFill(feature),
                stroke: parcelStroke(feature, resolution),
                radius: 5
            }),
            fill: parcelFill(feature),
            stroke: parcelStroke(feature, resolution),
            text: parcelText(feature, resolution)
        })
    };

    const parcelSource = new VectorSource({
        format: new GeoJSON(),
        loader: function (extent, resolution, projection) {
            var proj = projection.getCode();
            var url = `${window.apiUrl}/kasvulohko?crs=${proj}&bbox=${extent.join(',')},${proj}${roundRef.current ? '&round='+roundRef.current:''}${elyRef.current? '&ely='+elyRef.current: ''}`;
            ServiceApi.getGeojson(url)
                .then((data) => {
                    parcelSource.addFeatures(parcelSource.getFormat().readFeatures(data));
                })
                .catch((error) => {
                    parcelSource.removeLoadedExtent(extent)
                })
        },
        strategy: bbox
    });

    const parcelLayer = new VectorLayer({
        source: parcelSource,
        style: parcelStyles,
        minZoom: 8,
        visible: true
    });

    const parcelPointClusterSource = new Cluster({
        source: parcelSource,
        geometryFunction: function (feature) {
            let centerCoord = olExtent.getCenter(feature.getGeometry().getExtent());
            return new Point(centerCoord);
        }
    });

    var styleCache = {};
    const parselPointClusteStyle = function (feature) {
        var size = feature.get('features').length;
        console.log('features', feature.get('features'));
        let color = statusColorExtra;
        for (let index = 0; index <  feature.get('features').length; index++) {
            const feature_ =  feature.get('features')[index];
            if (feature_.get('status') === null && feature_.get('extra') !== 1) {
                color = colorCluster;
                break;
            } else if (feature_.get('status') === 0) {
                color = statusColorOngoing;
                break;
            } else if ( feature_.get('status') === 1) {
                color = statusColorObserved;
            }
        }

        let  style = new Style({
                image: new Circle({
                    fill: new Fill({
                        color: color
                    }),
                    stroke: new Stroke({
                        color: color
                    }),
                    radius: 14
                }),
                fill: new Fill({
                    color: color
                }),
                stroke: new Stroke({
                    color: colorClusterStroke
                }),
                text: new Text({
                    text: size.toString(),
                    fill: new Fill({
                        color: textColor
                    })
                })
            });

        return style;
    }

    const parcelPointLayer = new VectorLayer({
        source: parcelPointClusterSource,
        style: parselPointClusteStyle,
        maxZoom: 8,
        visible: true
    });

    const routeFill = new Fill({
        color: 'rgba(255,0,0,0.5)'
    });

    const routeStroke = new Stroke({
        color: 'rgb(255,0,0)',
        width: 2
    });

    const routeStyles = [
        new Style({
            image: new Circle({
                fill: routeFill,
                stroke: routeStroke,
                radius: 5
            }),
            fill: routeFill,
            stroke: routeStroke
        })
    ];

    //    let projectionName = 'EPSG:4326';
    const routeSource = new VectorSource({
        format: new GeoJSON(),
        loader: function (extent, resolution, projection) {
            var proj = projection.getCode();
            var url = `${window.apiUrl}/ajoreitti?crs=${proj}&bbox=${extent.join(',')},${proj}${elyRef.current? '&ely='+elyRef.current: ''}`;
            ServiceApi.getGeojson(url)
                .then((data) => {
                    routeSource.addFeatures(routeSource.getFormat().readFeatures(data));
                })
                .catch((error) => {
                    routeSource.removeLoadedExtent(extent)
                })
        },
        strategy: bbox
    });

    const routeLayer = new VectorLayer({
        source: routeSource,
        style: routeStyles,
        minZoom: 5,
        visible: true
    });

    // Interactions
    const selectParcelInteraction = new Select({
        layers: [parcelLayer],
        style: parcelStyles,
        condition: click
    });
    const selectParcelPointInteraction = new Select({
        layers: [parcelPointLayer]
    });

    // Init map instance
    const defaultInteractions = defaults({ altShiftDragRotate: false, pinchRotate: false });
    defaultInteractions.push(selectParcelInteraction);
    defaultInteractions.push(selectParcelPointInteraction);

    let centerMap = null;

    let roundControls=[]
    roundControls.push( new RoundControl(1, (val) => setRound(val)));
    roundControls.push( new RoundControl(2, (val) => setRound(val)));
    roundControls.push( new RoundControl(3, (val) => setRound(val)));

    const [map] = useState(new Map({
        layers: [
            osmLayer,
            parcelPointLayer,
            parcelLayer,
            routeLayer
        ],
        view: new View({
            projection: proj.getCode(),
            center: getCenter(projExtent),
            zoom: 10
        }),
        interactions: defaultInteractions,
        controls: [
            new Zoom(),
            new GeoLocationControl(proj, (coords) => {centerMap(coords)}),
            roundControls[0],
            roundControls[1],
            roundControls[2],
        ],
        moveTolerance: 3
    }));

    if (!centerMap) {
        centerMap = (coords) => {map.getView().setCenter(coords)}
    }

    useEffect(() => {
        if (map && roundControls) {
            elyRef.current=ely;
            roundRef.current=round
            map.getLayers().forEach(layer => layer.getSource().refresh());
            roundControls.forEach(roundControl => roundControl.toggleselected(round));
        }
    }, [ely,round,map,roundControls])

    useEffect(() => {
        const zoomout = () => {
            map.getView().animate({ zoom: map.getView().getZoom() > 10 ? 10 : map.getView().getZoom() });
        }

        const zoomToId = (selectedId) => {
            const view = map.getView();
            var proj = view.getProjection().getCode();
            var url = `${window.apiUrl}/kasvulohko/${selectedId.id}?crs=${proj}`;
            ServiceApi.getGeojson(url)
                .then((data) => {
                    let format = new GeoJSON();
                    let f = format.readFeatures(data, { dataProjection: proj, featureProjection: proj });
                    let zoomToExtent = olExtent.createEmpty();
                    f.forEach((actualFeature) => {
                        const featureExtent = actualFeature.getGeometry().getExtent();
                        olExtent.extend(zoomToExtent, featureExtent);
                    });
                    if (selectedId.map) {
                        view.fit(zoomToExtent, { duration: 1000, padding: [300, 300, 300, 300] });
                    }
                    else {
                        view.fit(zoomToExtent, { duration: 1000, padding: [50, 500, 50, 50] });;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }

        console.log('updating', selectedId);
        if (selectedId.id !== 0) {
            zoomToId(selectedId);
        }
        else {
            zoomout();
        }
        selectedIdRef.current = selectedId;
    }, [selectedId, map])

    // Mount
    useEffect(() => {
        function onParcelSelect(evt) {
            if (selectedIdRef.current.saved) {
                let select = evt.selected[0].get('id') !== selectedIdRef.current.id ||
                    (evt.selected[0].get('id') === selectedIdRef.current.id && selectedIdRef.current.map)
                if (select) {
                    setSelectedId({ id: evt.selected[0].get('id'), saved: true });
                    //  const featureExtent = evt.selected[0].getGeometry().getExtent();
                    //  map.getView().fit(featureExtent, { duration: 500, padding: [50, 500, 50, 50] });
                }
                else {
                    setSelectedId({ id: 0, saved: true });
                    // const view = map.getView()
                    // console.log(view.getZoom());
                    // view.animate({ zoom: view.getZoom() > 10 ? 10 : view.getZoom() });
                }
            }
            else {
                setInfoVisible(true);
            }
            selectParcelInteraction.getFeatures().clear();
        };

        function onParcelPointSelect(evt) {
            let zoomToExtent = olExtent.createEmpty();
            evt.selected.forEach((clusterFeature) => {
                const actualFeatures = clusterFeature.get('features');
                actualFeatures.forEach((actualFeature) => {
                    const featureExtent = actualFeature.getGeometry().getExtent();
                    olExtent.extend(zoomToExtent, featureExtent);
                });
            });
            selectParcelPointInteraction.getFeatures().clear();
            map.getView().fit(zoomToExtent, { duration: 500, padding: [50, 500, 50, 50] });
        };

        selectParcelPointInteraction.on('select', onParcelPointSelect);
        if (setSelectedId) {
            selectParcelInteraction.on('select', onParcelSelect);
        }
        map.setTarget('ruvi-map');

        return () => {
            // Unmount
            selectParcelPointInteraction.un('select', onParcelPointSelect);
            selectParcelInteraction.un('select', onParcelSelect);
            map.setTarget(null);
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div id='ruvi-map'>
            {Dialogs.Information('Sinulla on tallentamattomia muuutoksia lomakkeella',
                'Tallenna ne ensin tai peruuta muutokset lomakkeella', infoVisible, setInfoVisible)}
        </div>
    );
}

export default RuviMap;
