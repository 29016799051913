import React, { useState } from 'react';
import * as RuviUserLevels from './RuviUserLevels';

const Menu = ({ history, me, setMe, logout, elyt}) => {

    const [menuvalikko, setMenuvalikko] = useState(null);
    const [alimenuvalikko, setAliMenuvalikko] = useState(null);

    return (
        <span>
        <div className={`dropdown ${menuvalikko}`}>
            <div className="dropdown-trigger">
                <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={() => { menuvalikko ? setMenuvalikko(null) : setMenuvalikko('is-active'); setAliMenuvalikko(null); }}>
                    <i className="fas fa-lg fa-bars"></i>
                </button>
            </div>
            <div className="dropdown-content">
                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                    <a href="#form" className={`dropdown-item ${history.location.pathname === '/form' ? 'is-active' : ''}`} onClick={() => { setMenuvalikko(false); history.push('/form') }}>
                        Kartta
                    </a>
                    <hr className="dropdown-divider"></hr>
                    <a href="#start" className={`dropdown-item ${history.location.pathname === '/start' ? 'is-active' : ''}`} onClick={() => { setMenuvalikko(false); history.push('/start') }}>
                        Kasvulohkot
                    </a>
                    <hr className="dropdown-divider"></hr>
                    <a href="#summary" className={`dropdown-item ${history.location.pathname === '/summary' ? 'is-active' : ''}`} onClick={() => { setMenuvalikko(false); history.push('/summary') }}>
                        Yhteenveto
                    </a>
                    <hr className="dropdown-divider"></hr>
                    <a href="#start" className="dropdown-item" onClick={() => { setMenuvalikko(false); logout() }}>
                        Kirjaudu ulos
                    </a>
                </div>
            </div>
        </div>
        {me && parseInt(me.taso) === RuviUserLevels.OBS_RUVI_ADMIN_USER && <div className={`dropdown ${alimenuvalikko}`}>
        <div className="dropdown-trigger">
            <button className="button" aria-haspopup="true" aria-controls="dropdown-submenu" onClick={() => { alimenuvalikko ? setAliMenuvalikko(null) : setAliMenuvalikko('is-active'); setMenuvalikko(null); }}>
                Valitse ELY-keskus
            </button>
        </div>
        <div className="dropdown-content">
            <div className="dropdown-menu" id="dropdown-submenu" role="menu">
                {elyt && elyt.sort((a,b) => (a.elytunnus - b.elytunnus)).map((ely) => (
                <a href={`#${history.location.pathname}`} className={`dropdown-item ${ely.elytunnus === me.elytunnus ? 'is-active' : ''}`} key={ely.elytunnus} onClick={() => { setAliMenuvalikko(false); setMe({...me, elytunnus: ely.elytunnus, ely: ely.ely}); }}>
                    {ely.elytunnus} - {ely.ely}
                </a>
                ))}
            </div>
        </div>
    </div>}
    </span>
    )
}

export default Menu