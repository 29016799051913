import { Control } from 'ol/control';

export default class RoundControl extends Control {

    constructor(myround, setRound) {

        let myRound = myround;

        const btnListener = (evt) => {
            //const map = this.getMap();
            setRound(myRound);
        };

        let btnIcon = document.createElement('span');
        btnIcon.innerHTML = myRound;

        let btn = document.createElement('button');
        btn.type = 'button';
        btn.addEventListener('click', btnListener);
        btn.appendChild(btnIcon);
        btn.className = 'ol-round-off';
        btn.id = `ol-round-${myround}`;

        let classNames = `ol-round-${myround} ol-control ol-unselectable`;

        let customEl = document.createElement('div');
        customEl.className = classNames;
        customEl.appendChild(btn);
        
        super({element: customEl});
        this.myRound = myRound;
    }

    toggleselected(round) {
        let btn = document.getElementById(`ol-round-${this.myRound}`)
        if (btn) {
            btn.className = this.myRound === round ? 'ol-round-on': 'ol-round-off';
        }
        return;
    }

}
