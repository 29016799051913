import './RuviSummary.css';

import React, { useState, useEffect } from 'react';
import ServiceApi from './ServiceApi';
import Menu from './Menu';
import Utils from './Utils';
import * as RuviUserLevels from './RuviUserLevels';

const RuviSummary = ({ history, me, setMe, havaintolomake, logout, round, setRound, kasvikoodi, elyt}) => {

    const [havaintokasvulohkot, setHavaintokasvulohkot] = useState(null);
    const [havaintokasvulohkotiedot, setHavaintokasvulohkotiedot] = useState(null);
    const [allParcelsChecked, setAllParcelsChecked] = useState(false);
    const [tavoite, setTavoite] = useState(null);
    const [loading, setLoading] = useState(false);

    const getHavaintoKasvulohkot = async (me, all) => {
        if (me && (me.elytunnus || all)) {
            var loadingtimer = setTimeout(() => setLoading(true), 1000);
            await ServiceApi.getTavoite(setTavoite, all ? null : me.elytunnus);
            await ServiceApi.getHavaintoKasvulohkot(setHavaintokasvulohkot, all ? null : me.elytunnus);
            setLoading(false);
            clearTimeout(loadingtimer);
        }
    }

    useEffect(() => {
        getHavaintoKasvulohkot(me, allParcelsChecked);
    }, [me, allParcelsChecked]
    );

    useEffect(() => {
        if (havaintokasvulohkotiedot) {
            var fileURL = window.URL.createObjectURL(new Blob([havaintokasvulohkotiedot]));
            var fileLink = document.createElement('a');
    
            fileLink.href = fileURL;
            fileLink.setAttribute('download', Utils.getFormattedDateTime() + '_maastotiedonkeruu_havainnot.json');
            document.body.appendChild(fileLink);
            fileLink.click();
        }
    }, [havaintokasvulohkotiedot]
    );

    const downloadObsData = async () => {
        var loadingtimer = setTimeout(() => setLoading(true), 1000);
        await ServiceApi.getHavaintoKasvulohkotiedot(setHavaintokasvulohkotiedot, allParcelsChecked ? null : me.elytunnus);
        setLoading(false);
        clearTimeout(loadingtimer);
    }

    const summary = () => {
        if (!havaintolomake || !havaintokasvulohkot) {
            return null;
        }

        return (
            tavoite && tavoite.map((tavoite) => (
                <tr key={`row-${tavoite.kasvikoodi}`}>
                    <td key={`name-${tavoite.kasvikoodi}`}>{tavoite.kasvikoodi}{kasvikoodi && kasvikoodi.filter((kasvikoodi) => (kasvikoodi.koodi === tavoite.kasvikoodi))
                        .map((kasvikoodi) => (' - ' + kasvikoodi.nimi))}
                    </td>
                    {havaintolomake.map((lomake) => {
                        let all = havaintokasvulohkot.filter(lohko => lohko.todettukasvikoodi ? (lohko.todettukasvikoodi === tavoite.kasvikoodi) : (lohko.ilmoitettukasvi === tavoite.kasvikoodi))
                        let done = all.filter(lohko => lohko.havaintokierrokset && lohko.havaintokierrokset.filter(kierros => kierros.havaintokierros === lomake.havaintokierros && kierros.status === 1).length > 0);
                        return (
                            <td 
                                key={`summary-${tavoite.kasvikoodi}-${lomake.havaintokierros}-${Math.random()}`}>
                                <i className={`fas fa-md fa-circle ${done.length - tavoite.tavoite >= 0 ? 'has-text-success' : (done.length ? 'has-text-warning': 'has-text-danger')}`}></i>                                       
                                <span>{done.length}/{tavoite.tavoite}</span>
                            </td>
                        )
                    }
                    )}
                </tr>))
        );
    }

    return (
        <div id='ruvi-summary' className="field">
                <div className={`pageloader is-left-to-right  ${loading ? 'is-active':''}`}><span className="title">Lataa....</span></div>
                <div className="rows">
                    <div className="row">
                        <div className="columns">
                            <div className="column">
                                <div>{`Olet kirjautunut käyttäjänä ${me && me.nimi}, ${me && me.ely}`}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="columns is-vcentered">
                            <div className="column is-narrow">
                                <Menu history={history} me={me} setMe={setMe} logout={logout} elyt={elyt}/>
                                {me && parseInt(me.taso) === RuviUserLevels.OBS_RUVI_ADMIN_USER &&
                                <button className="button" onClick={() => { downloadObsData(); }}>
                                    <i className="fas fa-lg fa-download"></i>
                                </button>}
                            </div>
                            <div className="column">
                                <h1 className="title">Yhteenveto</h1>
                            </div>
                            {me && parseInt(me.taso) === RuviUserLevels.OBS_RUVI_ADMIN_USER &&
                            <div className="column has-text-right-tablet">
                                <input className="is-checkradio is-medium" type="checkbox" checked={allParcelsChecked}
                                    id='allparcels' name='allparcels'
                                    onChange={() => {setAllParcelsChecked(!allParcelsChecked)}} />
                                <label htmlFor="allparcels">Koko maa</label>
                            </div>}
                        </div>
                    </div>
                </div>
                <div className="hero">
                    <table className="table">
                        <thead>
                            <tr key="row-header">
                            <th key="header-plantcode">Kasvi</th>
                            {havaintolomake && havaintolomake.map((kierros) => (<>
                                <th key={`header-${kierros.havaintokierros}`}>#{`${kierros.havaintokierros}`}</th>
                                </>
                            ))}
                            </tr>
                        </thead>
                        <tfoot>
                            <tr key="row-footer">
                            <th key="header-plantcode">Kasvi</th>
                            {havaintolomake && havaintolomake.map((kierros) => (<>
                                <th key={`footer-${kierros.havaintokierros}`}>#{`${kierros.havaintokierros}`}</th>
                                </>
                            ))}
                            </tr>
                        </tfoot>
                        <tbody>
                        {summary()}
                        </tbody>
                    </table>
                </div>
            </div>
    )
}

export default RuviSummary;